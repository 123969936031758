const data = [
  { count: "$80M", desc: "saved" },
  { count: "500M", desc: "kWH avoided" },
  { count: "500K", desc: "vCPU Capacity" },
]

export const CountOn = () => {
  return (
    <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px]" style={{ background: "var(--blue-text)" }}>
      <div className="flex flex-col items-center text-white">
        <div className="flex items-center justify-center gap-x-[10rem] max-lg:gap-x-[3rem] gap-y-[1rem] w-full max-w-[1440px] flex-wrap">
          {data?.map((itm, idx) => (
            <div className="text-center" key={idx}>
              <p className="text-[48px] font-[600] max-md:text-[26px] uppercase">
                {itm.count}
              </p>
              <p className="max-sm:text-[16px] text-lg">
                {itm.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
