import balanceImg from "../../../../assets/images/bucket/PartnerPage/balance.png";
import { useTheme } from "../../../../features/theme/use-theme";

export const CurrentBalans = ({ balance, onClick }) => {
  const [theme] = useTheme();
  return (
    <div
      className={`flex flex-col w-full py-[28px] px-[24px] border-[1px] border-solid border-[#E1E1E1] rounded-[15px] ${
        theme === "dark" ? "bg-[#1B1F27]" : "bg-[#FCFCFC]"
      }`}
    >
      <p
        className={`font-[600] text-[22px] leading-[32px] mb-[36px] ${
          theme === "dark" ? "text-[#92929D]" : ""
        }`}
      >
        Current Balance
      </p>
      <div className="flex w-full justify-between">
        <div className="flex items-center">
          <img
            src={balanceImg}
            alt="balance"
            className="mr-[24px] w-[48px] h-[48px] max-md:hidden"
          ></img>
          <div className="flex flex-col">
            <p
              className={`font-[600] text-[13px] leading-[16px] mb-[8px] ${
                theme === "dark" ? "text-[#92929D]" : "text-[#6F767E]"
              }`}
            >
              Current account balance
            </p>
            <p
              className={`font-[600] text-[48px] leading-[48px] ${
                theme === "dark" ? "text-[#b7b7b7]" : "text-black"
              }`}
            >
              {balance?.balance || 0} {balance?.currency || "USD"}
            </p>
          </div>
        </div>
        <button
          onClick={onClick}
          className={`text-white rounded-[15px] font-[600] px-[20px] w-fit text-[15px] h-[48px]
            ${theme === "dark" ? "bg-[#818181]" : "bg-black"}
            `}
        >
          Withdraw balance
        </button>
      </div>
    </div>
  );
};
