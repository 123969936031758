import { Link } from "react-router-dom";
import { Header } from "../UI/Main/Header";
import { Footer } from "./Footer/Footer";
import cloudIcon from "../../assets/images/main/cloud.svg";
import objectIcon from "../../assets/images/main/object.svg";

const data = [
  {
    icon: cloudIcon,
    title: "Outage Credits",
    desc: "If we have downtime, credits will be provided to all customers who were effective.",
  },
  {
    icon: objectIcon,
    title: "100% Cloud Uptime",
    desc: "We've implemented redundant systems at every level, to put a stop to downtime.",
  },
];

const cloudCompute = [
  {
    time: "Less than 10 minutes",
    credit: "1 hour",
  },
  {
    time: "Up to an hour",
    credit: "6 hours",
  },
  {
    time: "Up to 2 hours",
    credit: "12 hours",
  },
  {
    time: "Up to 6 hours",
    credit: "24 hours",
  },
  {
    time: "Up to 1 hours",
    credit: "36 hours",
  },
  {
    time: "Up to 1 day",
    credit: "168 hours",
  },
];

const objectStorage = [
  {
    time: "Less than 10 minutes",
    credit: "$0.15 per TB",
  },
  {
    time: "Up to an hour",
    credit: "$0.35 per TB",
  },
  {
    time: "Up to 2 hours",
    credit: "$0.75 per TB",
  },
  {
    time: "Up to 6 hours",
    credit: "$0.75 per TB",
  },
  {
    time: "Up to 1 hours",
    credit: "$1.25 per TB",
  },
  {
    time: "Up to 1 day",
    credit: "$2.50 per TB",
  },
];

export default function Uptime() {
  return (
    <div>
      <Header />

      <div className="mb-[100px] max-md:mb-[50px] mt-[85px] max-md:mt-[50px] max-w-[1440px] mx-auto">
        <div className="flex flex-col w-full h-full max-w-[1440px] px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
          <div className="flex items-center flex-col gap-y-4 w-full text-center">
            <h1
              className="text-[42px] mb-[32px] font-[700] max-md:text-[32px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Uptime is Non-Negotiable
            </h1>

            <p className="text-[18px] max-sm:text-base max-w-[575px] mb-[30px]">
              We understand that your business operates in a world that never
              sleeps. That's why we're committed to providing you with an
              unmatched uptime guarantee, ensuring that your operations run
              seamlessly 24/7.
            </p>

            <Link
              to="#"
              style={{ background: "var(--blue-text)" }}
              className="px-8 py-[16px] text-white rounded-[15px] font-[500]"
            >
              Read more
            </Link>
          </div>
        </div>
      </div>

      <div
        className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px]"
        style={{ background: "var(--blue-text)" }}
      >
        <h1
          className="text-[42px] font-[700] max-md:text-[32px] max-sm:text-[18px] text-white text-center"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Distributed Computing = 100% Uptime
        </h1>
      </div>

      <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px] bg-[#fbfbfb]">
        <h1
          className="text-[48px] max-md:text-[32px] text-center font-[700] mb-[60px] max-md:mb-[32px]"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Our promises
        </h1>
        <div className="flex flex-wrap justify-center gap-x-12 gap-y-8">
          {data?.map((itm, idx) => (
            <div
              className="flex flex-col items-center max-w-[330px] max-xl:max-w-[270px] max-lg:max-w-[230px]"
              key={idx}
            >
              <div className="w-[80px] h-[80px] bg-[#f3f3f3] rounded-lg grid place-items-center">
                <img
                  src={itm.icon}
                  width={60}
                  height={60}
                  alt="Cloud Computing"
                />
              </div>
              <h5
                className="text-xl max-md:text-lg font-[700] my-4"
                style={{ fontFamily: "var(--family-ubuntu)" }}
              >
                {itm.title}
              </h5>
              <p className="max-sm:text-sm text-center text-[#3f405b]">
                {itm.desc}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px]">
        <h1
          className="text-[48px] text-center mb-[32px] font-[700] max-md:text-[32px] max-md:mb-[20px]"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Outage Credits
        </h1>

        <p className="text-[18px] max-sm:text-base max-w-[575px] text-center mb-[30px] mx-auto font-[500]">
          In the event our availability falls below our guarantees any instances
          be eligible for credits based on any affected mediums.
        </p>

        <div className="flex gap-y-8 gap-x-16 justify-center flex-wrap">
          <div>
            <p className="text-center text-lg font-[600] mb-4">Cloud Compute</p>
            {cloudCompute.map((itm, idx) => (
              <div
                key={idx}
                className="flex items-center gap-2 mb-2 last:mb-0"
              >
                <p className="border-[1px] w-[170px] max-sm:text-sm max-sm:max-w-[164px] border-solid p-2 border-[#c9c9c9] rounded-md text-sm">
                  {itm.time}
                </p>
                <p className="border-[1px] w-[170px] max-sm:text-sm max-sm:max-w-[164px] border-solid p-2 border-[#c9c9c9] rounded-md text-sm">
                  {itm.credit}
                </p>
              </div>
            ))}
          </div>
          <div>
            <p className="text-center text-lg font-[600] mb-4">
              Object Storage
            </p>
            {objectStorage.map((itm, idx) => (
              <div
                key={idx}
                className="flex items-center gap-2 mb-2 last:mb-0"
              >
                <p className="border-[1px] w-[170px] border-solid p-2 border-[#c9c9c9] rounded-md text-sm">
                  {itm.time}
                </p>
                <p className="border-[1px] w-[170px] border-solid p-2 border-[#c9c9c9] rounded-md text-sm">
                  {itm.credit}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
