import { Footer } from "./Footer/Footer";
import { Header } from "../UI/Main/Header";

export default function Term() {
  return (
    <div>
      <Header />
      <div className="mb-[100px] max-md:mb-[50px] mt-[65px] max-md:mt-[40px] max-w-[1440px] mx-auto">
        <div className="flex flex-col w-full h-full max-w-[1440px] px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
          <div className="w-full">
            <h1
              className="text-[38px] mb-[32px] font-[700] max-md:text-[28px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Introduction
            </h1>

            <p className="mb-[30px]">
              <a href="/">Clomd.com</a> (“Clomd” or our “website”) is owned and
              operated by REECARD LLC, a California limited liability company
              located at 145 Speckled Alder, Irvine CA, 92618. REECARD LLC is
              referred to as “we”, “us”, “our” and other similar terms in these
              Terms of Service.
            </p>

            <p className="mb-[30px]">
              <a href="/">Clomd.com</a> offers cloud storage, file storage,
              temporary storage and sharing services (the “services”). These
              services are meant to help people and businesses handle files and
              make creative applications for others while keeping storage costs
              fair and understandable.
            </p>

            <p className="mb-[30px]">
              These Terms of Service constitute a binding agreement between you
              and us.
            </p>

            <p className="text-lg">
              <strong>
                1. LEGAL AGE: You must be of legal age to use our website and
                our services.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              You must be at least 13 years old if you live in the United States
              to use our services; and 16 years old if you live in the European
              Union. You must be at least 18 years old to provide payments for
              our services.
            </p>
            <p className="text-lg">
              <strong>
                2. ACCOUNT: To upload files and benefit from our services, you
                must create an account on our website. We may conduct
                investigations and close user accounts under specific
                circumstances.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              To use our services and make payments, you will need to create an
              account on our website by providing your current and valid email
              address.
            </p>
            <p className="mb-[26px] mt-2">
              Different email services carry different security. You are
              responsible for protecting your authentication. We help protect
              business accounts by making API keys available that are changed on
              a regular basis, usually every 30 days. For non developer related
              API keys, they are placed and changed every 7 days within the
              browser session or returned via the API, as selected.
            </p>
            <p className="mb-[26px] mt-2">
              <strong>
                We collect, store, and use your email only to communicate with
                you about your account. We do not sell, transfer, or provide
                your email apart from communicating to you for your account.
              </strong>{" "}
              Our data protection obligations are described in our Privacy
              Policy.
            </p>
            <p className="mb-[26px] mt-2">
              We may investigate any potential violation of these Terms of
              Service. We may terminate the account of a user who: (i) breached
              these Terms of Service, (ii) infringed copyrights or other
              intellectual property rights of others, (iii) abused our services
              (iv) posted illegal or prohibited content on our website. If your
              account is terminated, sections in these Terms of Service, such as
              those relating to liability and disclaimers, will continue to
              apply to you. If you believe your user account was terminated
              without valid reason, please send an email to{" "}
              <a href="mailto:support@Clomd.com">support@Clomd.com</a>
            </p>
            <p className="mb-[26px] mt-2">
              You can delete your account by emailing us. You may not delete
              your account if there is active action, like a takedown request or
              legal issue in connection with your account. Accounts limited due
              to nonpayment cannot be deleted. You must delete all files and
              folders using the API or email us your full name, billing address,
              and last four digits of the payment information you provided to be
              billed.
            </p>
            <p className="mb-[26px] mt-2">
              Deleting your account on our website is permanent and cannot be
              undone. We will store your data and files for 60 days after you
              delete your account. If we receive a subpoena or similar request
              from law enforcement, or if we have a legal obligation to
              disclose, you understand and agree that we will transfer any
              requested information to the requesting authority
            </p>

            <p className="text-lg">
              <strong>
                3. PAYMENTS AND BILLING: To provide a service, we need to bill
                you. Payments are made using Stripe.
              </strong>
            </p>

            <p className="mb-[26px] mt-2">
              Different personal and business plans are available for our
              services. The plans are subject to monthly fees. You agree to pay
              the fees for the services purchased on a recurring monthly basis
              via an accepted payment method. Payments may be made via Stripe or
              any authorized payment processor listed on our website.
            </p>
            <p className="mb-[26px] mt-2">
              We will store your credit card details using our partner Stripe (
              <a
                style={{ textDecoration: "underline" }}
                href="https://stripe.com/privacy"
              >
                https://stripe.com/privacy
              </a>{" "}
              /{" "}
              <a
                href="https://stripe.com/legal/ssa"
                style={{ textDecoration: "underline" }}
              >
                https://stripe.com/legal/ssa
              </a>
              ) to bill you. Stripe’s Service Agreement and other policies
              governing data processing and billing will apply to you
            </p>
            <p className="mb-[26px] mt-2">
              We may bill you using other payment processors, such as in the
              case of bank transfers or via a card. We may use your payment
              information or personal data for the purpose of preventing fraud
              or spam.
            </p>
            <p className="mb-[26px] mt-2">
              After the services are completed, we will invoice the authorized
              payment method (bank or credit card) on a monthly basis or as
              otherwise defined.
            </p>
            <p className="mb-[26px] mt-2">
              We charge you based on the size of your account's files in
              Terabytes (TB). For easier understanding, think of 1 byte as the
              smallest unit of measurement for your file size on our service,
              and it is equal to 1e-12 (0.000000000001) Terabytes. To provide
              context, a Terabyte is equivalent to 1000 Gigabytes (GB).
            </p>
            <p className="mb-[26px] mt-2">
              For businesses, billing will be based on their maximum usage at
              the advertised rate, rounded up to the next integer, measured in
              Terabytes (TB). If the plan includes data transfer rates or egress
              fees, you will be billed according to the rate in Terabytes,
              rounded to the nearest dollar, with a minimum fee of $2.5 per
              month.
            </p>
            <p className="mb-[26px] mt-2">
              If your payment information, such as the credit card provided, is
              invalid, blocked or otherwise unusable, we may not provide the
              services ordered. If you believe that we have incorrectly provided
              you an invoice or charged a payment method, you should reach out
              to us via <a href="mailto:support@Clomd.com">support@Clomd.com</a>
              .
            </p>
            <p className="mb-[26px] mt-2">
              <strong>
                Nonpayment for the usage can cause your files to be deleted.
              </strong>{" "}
              We will notify you via email or otherwise prior to your files
              being deleted or purged due to nonpayment. We will grant you a
              5-day delay to settle the outstanding balance before your files
              start to be purged. Please contact our support team if you wish to
              provide a justification for nonpayments. We reserve the right to
              use your information to collect payments via collections if we
              determine the amount is excessive.
            </p>
            <p className="mb-[26px] mt-2">
              <strong>
                We are firm believers in fair billing. There are no hidden
                charges.
              </strong>{" "}
              All monthly fees for our services are clearly listed on our
              website, inclusive of any taxes.
            </p>
            <p className="mb-[26px] mt-2">
              <strong>
                Do not use stolen payment information. We will refund you if we
                believe the transaction is fraudulent.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              You should expect the description of our services on your credit
              card statement to be listed as follows: REECARD*Clomd or REECARD
              LLC. You confirm that you are authorized to pay for the services
              using the credit card provided or that you secured the payment
              holder’s consent if you use the payment information that does not
              belong to you or your company.
            </p>
            <p className="text-lg">
              <strong>
                4. FILE SECURITY: We care about your file security and have
                different types of security. We take measures to make sure
                unauthorized users do not access your content.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              We implemented appropriate technical and organizational measures
              to protect the files uploaded to our website from unauthorized
              access, use, and disclosure. However, we cannot guarantee the
              security of the files uploaded to our website. You should
              therefore take the necessary precautions to protect your files.
            </p>
            <p className="mb-[26px] mt-2">
              We use encryption to encrypt your files and your personal
              information (e.g., your email address) is encrypted in our
              database or our processors. Our corporate systems use data centers
              or noncorporate providers to store segments of your files, and may
              transfer data among them.
            </p>
            <p className="mb-[26px] mt-2">
              There are different levels of security applicable to your file
              storage:
            </p>

            <table class="border-collapse border border-slate-500 w-full mb-4">
              <thead>
                <tr>
                  <th class="text-start border border-slate-600 w-[40%] p-2">
                    Type of Security
                  </th>
                  <th class="text-start border border-slate-600 w-[60%] p-2">
                    Access
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border border-slate-700 p-2">Unlisted</td>
                  <td class="border border-slate-700 p-2">
                    The files can be accessed via a specific link, such as a
                    fileID. This is the default. For more information on a
                    fileID, please see below.
                  </td>
                </tr>
                <tr>
                  <td class="border border-slate-700 p-2">Shared</td>
                  <td class="border border-slate-700 p-2">
                    Only you, or others will have access, assuming you have
                    allowed them to view, access, and download the files you
                    have shared.
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mb-[26px] mt-2">
              We use IDs to identify files/folders without naming them, allowing
              for multiple files of the same name and content type.
            </p>
            <p className="text-lg">
              <strong>
                5. PERSONAL PLANS: Personal plans are not for businesses. Do not
                abuse the service or store files stored on behalf of others
                within your file drive.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              Personal users and any unlimited pricing are for personal use
              only. If we determine that you are abusing the service based on
              your total file usage or for other reasons, we will notify you by
              email, and send you an appropriate business pricing plan.
            </p>
            <p className="text-lg">
              <strong>
                6. MONEY BACK GUARANTEE: Personal users benefit from a
                money-back guarantee.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              If you are a personal user who bought any Personal plan and are
              dissatisfied with the services, you may contact us at
              <a href="mailto:support@Clomd.com">support@Clomd.com</a> to
              request a refund. Please ensure you email us from the same email
              address used for your purchase. If you do contact us from another
              email, please mention your linked email, name, address, and
              payment information (such as the bank or last 4 digits of the
              credit card used to make the purchase).
            </p>
            <p className="text-lg">
              <strong>
                7. FEEDBACK: We will own any feedback you provide to us, but we
                do not have to respond to your feedback.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              You may provide any feedback about our service to us, via email or
              any other channel we own or operate in. We have no obligation to
              use your feedback. We will, however, own the feedback, and have
              the right to implement your feedback or use it to fix problems
              with our cloud sharing services.
            </p>
            <p className="text-lg">
              <strong>
                8. INTELLECTUAL PROPERTY: Your intellectual property belongs to
                you. We will use, store, process and distribute your files as
                part of our services.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              Any content or files that you upload or share on the website will
              be voluntarily provided by you. You own the intellectual property
              in connection with any files you upload or share on our website.
              We do not own your files.
            </p>
            <p className="mb-[26px] mt-2">
              You agree to upload files and content that you own, and which do
              not violate any third party intellectual property rights. We
              cannot be held responsible if any of your files or content
              infringe a third party’s rights, however, such a party may submit
              a takedown notice (as described in these Terms of Service).
            </p>
            <p className="mb-[26px] mt-2">
              All intellectual property relating to us and our website and
              services constitutes our sole property. Our trademarks and logos
              may not be reproduced or shared without our prior written consent.
            </p>
            <p className="mb-[26px] mt-2">
              After you transfer your files (uploading them to any website or
              connecting via our API), we store your files and distribute them
              with content delivery networks (CDNs). We may use third party
              processors to store and host these files. Below is a list of the
              processors who store your customer data or files:
            </p>

            <table class="border-collapse border border-slate-500 w-full mb-4">
              <thead>
                <tr>
                  <th class="text-start border border-slate-600 w-[40%] p-2">
                    Entity Name
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border border-slate-700 p-2">
                    The Constant Company, LLC d/b/a Vultr
                  </td>
                </tr>
                <tr>
                  <td class="border border-slate-700 p-2">Cloudflare, Inc.</td>
                </tr>
                <tr>
                  <td class="border border-slate-700 p-2">Backblaze, Inc.</td>
                </tr>
                <tr>
                  <td class="border border-slate-700 p-2">DigitalOcean, LLC</td>
                </tr>
              </tbody>
            </table>

            <p className="mb-[26px] mt-2">
              We may store encrypted segments on other devices not registered to
              entities. Other files are stored in parts, and metadata is
              removed, including the content type for your file.
            </p>
            <p className="mb-[26px] mt-2">
              We may share the files or upload metadata to other third-party
              providers to provide the services, improve download speeds and
              enhance our services.
            </p>

            <p className="text-lg">
              <strong>
                9. TAKEDOWN NOTICE: This section indicates how to notify us of
                potential copyright infringement and/or illegal content on our
                website.
              </strong>
            </p>

            <p className="mb-[26px] mt-2">
              We comply with the Digital Millennium Copyright Act ("DMCA") and
              respect intellectual property rights. The guidance provided in
              this section does not create any binding obligations upon us. All
              limitations and exclusions of liability set out in these Terms of
              Service apply equally to this guidance.
            </p>
            <p className="mb-[26px] mt-2">
              If you believe your copyrighted work was published on our website
              without your authorization, or to report a copyright infringement,
              please submit a takedown notice to us.
            </p>
            <p className="mb-[26px] mt-2">
              To submit a takedown notice, please send a notice by email (
              <a href="mailto:support@Clomd.com">support@Clomd.com</a>) or use
              our API and other tools we provide containing the following
              information:
            </p>
            <div className="pl-3">
              <p className="mb-2">
                1. Your manual or electronic signature if you are the copyright
                owner, OR the manual or electronic signature of the copyright
                owner’s authorized representative.
              </p>
              <p className="mb-2">
                2. A link to the copyrighted work on our website (URL) that is
                subject to your takedown notice.
              </p>
              <p className="mb-2">
                3. A description of the copyrighted work subject to the takedown
                notice, such as the date the work was created.
              </p>
              <p className="mb-2">
                4. Your name, mailing address, telephone number, and email
                address.
              </p>
              <p className="mb-2">
                5. A statement confirming that your takedown notice was prepared
                in good faith.
              </p>
              <p className="mb-2">
                6. A statement, under penalty of perjury, that the information
                in your takedown notice is accurate and that you are the
                copyright owner or authorized to act on the copyright owner's
                behalf.
              </p>
            </div>
            <p className="mb-[26px] mt-3">
              We will promptly review all takedown notices received. If your
              takedown notice is justified, we will take appropriate action,
              including removing the infringing material or disabling access.
              After we receive a takedown notice, we will notify the user who
              uploaded or provided the copyrighted work.
            </p>
            <p className="mb-[26px] mt-2">
              You may not abuse the takedown or report system that we have set
              up. Do not submit unfounded claims or for the copyrighted work
              that follows fair use.
            </p>
            <p className="mb-[26px] mt-2">
              We generally hide the copyrighted work from being discoverable by
              users while we assess takedown notices and counter-notices, if
              applicable.
            </p>
            <p className="text-lg">
              <strong>
                10. COUNTER-NOTICE: If you believe copyrighted work was removed
                by us without justification, you may submit a counter-notice.
              </strong>
            </p>

            <p className="mb-[26px] mt-2">
              A counter-notice should include the following information:
            </p>
            <div className="pl-3">
              <p className="mb-2">1. Your manual or electronic signature.</p>
              <p className="mb-2">
                2. A description of the copyrighted work that was removed or
                disabled and the location where it appeared (including a URL).
              </p>
              <p className="mb-2">
                3. A statement, under penalty of perjury, that you have a good
                faith belief that the copyrighted work was removed or disabled
                as a result of a mistake or misidentification.
              </p>
              <p className="mb-2">
                4. Your name, address, telephone number, and email address.
              </p>
              <p className="mb-2">
                5. The following statement: "I consent to the jurisdiction of
                the Federal District Court for the judicial district in which my
                address is located (if in the United States), and I will accept
                service of process from the person who provided the DMCA
                notification or an agent of such person or entity."
              </p>
            </div>
            <p className="mb-[26px] mt-2">
              If we receive a counter-notice, we may reinstate the material on
              our website.
            </p>
            <p className="text-lg">
              <strong>
                11. ILLEGAL CONTENT: We will remove illegal or prohibited
                content from our website.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              You may not publish, share, disseminate, or post content on the
              website that:
            </p>
            <div className="pl-3">
              <p className="mb-2">
                1. Is defamatory, harassing, illegal or abusive;
              </p>
              <p className="mb-2">2. Is obscene or sexually explicit;</p>
              <p className="mb-2">
                3. Promotes hate speech, violence or discrimination;
              </p>
              <p className="mb-2">
                4. Encourages or facilitates illegal activity or unlawful
                conduct;
              </p>
              <p className="mb-2">
                5. Infringes upon the trademarks or other intellectual property
                rights of others;
              </p>
              <p className="mb-2">
                6. Constitutes non-consensual distribution (including revenge
                porn and blackmail);
              </p>
              <p className="mb-2">
                7. Reveals personally identifiable information (including an
                individual’s name, address, phone number, IP address);
              </p>
              <p className="mb-2">
                8. Breaches any applicable law, order, regulation or policy;
              </p>
              <p className="mb-2">
                9. Is harmful, or contains viruses, malware, or other harmful
                software;
              </p>
              <p className="mb-2">
                10. Is intended to intimidate, harass, threaten or otherwise
                bother other users;
              </p>
              <p className="mb-2">
                11. Constitutes spam, chain letters and unsolicited materials;
                or
              </p>
              <p className="mb-2">
                12. Impersonates an individual, entity, or company, or is
                intentionally misleading.
              </p>
            </div>
            <p className="mb-[26px] mt-2">
              If you believe that illegal or prohibited content has been posted
              on our website, please submit a takedown notice, as described in
              these Terms of Service.
            </p>
            <p className="text-lg">
              <strong>
                12. LIABILITY: Our liability will be limited, or even excluded,
                in certain situations.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              To the fullest extent permitted by law, we will not be liable to
              you for any incidental, punitive, special or consequential
              damages, including but not limited to loss of data or files
              uploaded to our website, loss of income and other damages arising
              from your use of our website or our services. We will not be
              responsible for any service interruptions or issues impairing our
              website or the device used to access our website or our services.
            </p>
            <p className="mb-[26px] mt-2">
              You cannot sue us for any damages or losses in connection with
              your usage of our website and our services. Our responsibilities
              will be suspended during a Force Majeure event.
            </p>
            <p className="text-lg">
              <strong>
                13. NO WARRANTIES: Our website and our services are provided
                without warranties.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              Our website and our services are provided “as is”, without any
              warranties or guarantees, including merchantability or fitness for
              a particular purpose. More specifically, we cannot guarantee that
              our services will be provided without error, omission or
              interruption. This means we cannot promise that our services will
              always work as you would expect.
            </p>
            <p className="mb-[26px] mt-2">
              You acknowledge that there are risks associated with uploading
              files and making payments on an Internet-dependent platform.
              Consequently, you agree that your use of the website, provision of
              payments, and uploading of files on our website will be at your
              sole risk and expense.
            </p>
            <p className="text-lg">
              <strong>
                14. UPDATES: We may update these Terms of Service at any time.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              We may update these Terms of Service, at our discretion. The
              updated Terms of Service will be published on our website and will
              be applicable as of the date of publication.
            </p>
            <p className="text-lg">
              <strong>
                15. APPLICABLE LAW AND CLAIMS: These Terms of Service are
                governed by the laws of California, and all claims and suits
                must be introduced in the State of California.
              </strong>
            </p>
            <p className="mb-[26px] mt-2">
              These Terms of Service are subject to the laws of the State of
              California. Any claims associated with our services must be filed
              in the state or federal courts in the State of California. You
              agree that any claim filed must be in your individual capacity,
              and not as a class action.
            </p>
            <p className="mb-[26px] mt-2">
              We may assign our rights provided in these Terms of Service. You
              may not assign any of your rights or obligations under these Terms
              of Service, except with our prior written consent.
            </p>
            <p className="mb-[26px] mt-2">
              If any provision in these Terms of Service is found to be
              unenforceable, the provision will be removed. The remainder of
              these Terms of Service will be binding.
            </p>
            <p className="mb-[26px] mt-2">
              We welcome questions and comments regarding our website, and these
              Terms of Service. Any questions and comments should be sent on our
              website, or by email at{" "}
              <a href="mailto:support@Clomd.com">support@Clomd.com</a>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
