import "./App.css";
import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Main from "./components/Landings/Main";
import Uptime from "./components/Landings/Uptime";
import Pricing from "./components/Landings/Pricing";
import Sustainability from "./components/Landings/Sustainability";
import HowWorks from "./components/Landings/HowWorks";
import { Layout } from "./components/Layout";
import { ResultPayment } from "./components/UI/Popups/ResultPayment";
import { Checkout } from "./components/Landings/Checkout/Checkout";
import { BucketBlank } from "./components/Bucket/BucketBlank/BucketBlank";
import { Partner } from "./components/Bucket/Partner/Partner";
// import { Setting } from "./components/Bucket/Setting/Setting";
import { Billing } from "./components/Bucket/Billing/Billing";
import { Help } from "./components/Bucket/Help/Help";
import { BucketInside } from "./components/Bucket/BucketBlank/BucketInside";
import Computing from "./components/Computing";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Login } from "./components/Landings/Login/Login";
import { FolderInside } from "./components/Bucket/BucketBlank/FolderInside";
import { Payment } from "./components/Computing/Payment";
import { client } from "./API/client";
import { bytesToTB } from "./utils/formatBytes";
import ComputeAPI from "./API/computing";
import Privacy from "./components/Landings/Privacy";
import Term from "./components/Landings/Term";
import { Toaster } from "react-hot-toast";

function App() {
  const location = useLocation();
  const { apikey, email } = useSelector((state) => state.verifyUser);
  const { clientSecret } = useSelector((state) => state.clientSecret);
  const { autorization } = useSelector((state) => state.userAutorization);

  useEffect(() => {
    const getInfo = () => {
      return autorization, clientSecret, localStorage;
    };
    getInfo();
  }, [autorization, clientSecret, localStorage]);

  const usageBilled = async () => {
    client("accounts/usage", {
      headers: { "x-api-key": apikey },
    }).then(async (res) => {
      const usage = res?.usage;
      if (usage) {
        const TBs = bytesToTB(usage);
        let amount = 0;
        if (TBs < 1) {
          amount = 1;
        } else {
          amount = 2.5 * parseInt(TBs);
        }

        await ComputeAPI.createCharges({ email, amount });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const currentDate = new Date();
    const isFirstOfMonth = currentDate.getDate() === 1;
    if (isFirstOfMonth && email && apikey) {
      usageBilled();
    }
  }, [location.pathname]);

  return (
    <>
      {!clientSecret && (
        <Routes>
          <Route
            path="/"
            element={<Main />}
          />
          <Route
            path="/uptime"
            element={<Uptime />}
          />
          <Route
            path="/pricing"
            element={<Pricing />}
          />
          <Route
            path="/sustainability"
            element={<Sustainability />}
          />
          <Route
            path="/how-it-works"
            element={<HowWorks />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/checkout"
            element={<Checkout />}
          />
          <Route
            path="/term"
            element={<Term />}
          />
          <Route
            path="/privacy"
            element={<Privacy />}
          />
        </Routes>
      )}
      {clientSecret && (
        <>
          <div className="w-full h-full flex flex-col items-center">
            {!autorization && (
              <Routes>
                <Route
                  path="/"
                  element={<Main />}
                />
                <Route
                  path="/uptime"
                  element={<Uptime />}
                />
                <Route
                  path="/pricing"
                  element={<Pricing />}
                />
                <Route
                  path="/sustainability"
                  element={<Sustainability />}
                />
                <Route
                  path="/how-it-works"
                  element={<HowWorks />}
                />
                <Route
                  path="/login"
                  element={<Login />}
                />
                <Route
                  path="/checkout"
                  element={<Checkout />}
                />
                <Route
                  path="/privacy"
                  element={<Privacy />}
                />
                <Route
                  path="/term"
                  element={<Term />}
                />
                <Route
                  path="/completion/:success"
                  element={<ResultPayment />}
                />
              </Routes>
            )}
          </div>
          <div
            className="w-full h-full flex flex-col items-center"
            style={{ fontFamily: "var(--family-ubuntu)" }}
          >
            <Routes>
              {autorization && (
                <Route
                  path="/"
                  element={<Layout />}
                >
                  <Route
                    index
                    element={<Computing />}
                  />
                  <Route
                    path="payment"
                    element={<Payment />}
                  />
                  <Route
                    path="bucket"
                    element={<BucketBlank />}
                  />
                  <Route
                    path="bucket/:id"
                    element={<BucketInside />}
                  />
                  <Route
                    path="bucket/:id/folder/:folderId"
                    element={<FolderInside />}
                  />
                  <Route
                    path="completion/:success"
                    element={<ResultPayment />}
                  />
                  <Route
                    path="partner"
                    element={<Partner />}
                  />
                  {/* <Route
                    path="setting"
                    element={<Setting />}
                  /> */}
                  <Route
                    path="billing"
                    element={<Billing />}
                  />
                  <Route
                    path="help"
                    element={<Help />}
                  />
                </Route>
              )}
            </Routes>
          </div>
        </>
      )}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  );
}

export default App;
