import { client } from "../API/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";

export const useGetReferralBalance = () => {
  const { apikey } = useSelector((state) => state.verifyUser);
  const [balance, setBalance] = useState();

  useEffect(() => {
    client("accounts/refer/balance", {
      headers: { "x-api-key": apikey },
    }).then((res) => setBalance(res));
  }, []);

  return [balance];
};
