import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import ClomdLogo from "../../../assets/images/main/clomdLogo.svg";
import { useModalContext } from "../../../context/modalContext";

export const Footer = () => {
  const { setShowSignPopup } = useModalContext();

  return (
    <div className="w-full bg-[#f6f6f6] pt-[96px] pb-[50px] flex justify-center max-md:py-[50px] max-sm:py-[20px] text-base text-black">
      <div className="flex flex-col w-full justify-between max-w-[1440px] px-[100px] max-md:px-[50px] max-sm:px-[20px]">
        <div className="w-full flex flex-wrap justify-between border-solid pb-[110px] max-md:pb-[50px] max-sm:pb-[20px] max-md:gap-y-4">
          <div className="w-[40%] max-md:w-[50%] max-sm:w-full">
            <Link to="/">
              <img
                className="max-md:w-[120px] max-md:h-[52px]"
                src={ClomdLogo}
                width={130}
                height={56}
                loading="lazy"
                alt="Clomd logo"
              />
            </Link>
            <p className="text-sm text-[#3f405b] max-w-[270px] max-sm:max-w-full">
              Clomd, its cloud with a mission. An optimized distributed
              computing network. Data is hosted on Tier 3/4 datacenters, SOC
              Type II, GDPR, and ISO complaint.{" "}
            </p>
          </div>
          <div className="w-[20%] pl-2 max-sm:pl-0 max-md:w-[50%] max-sm:w-full">
            <h5 className="font-[700] mb-3">Legal</h5>
            <div className="font-[500] flex gap-2 flex-col text-[#3f405b]">
              <Link to="/term">Terms of Service</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
          <div className="w-[20%] pl-2 max-md:pl-0 max-md:w-[50%] max-sm:w-full">
            <h5 className="font-[700] mb-3">Quick Links</h5>
            <div className="font-[500] flex gap-2 flex-col text-[#3f405b]">
              <Link to="/">Home</Link>
              <Link to="/pricing">Pricing</Link>
              <NavHashLink
                smooth
                to="/#services"
              >
                Services
              </NavHashLink>
              <Link to="/how-it-works">How it works</Link>
            </div>
          </div>
          <div className="w-[20%] pl-2 max-sm:pl-0 max-md:w-[50%] max-sm:w-full">
            <div>
              <h5 className="font-[700] mb-3">Services</h5>
              <div className="font-[500] flex gap-2 flex-col text-[#3f405b]">
                <Link
                  to="#"
                  onClick={(e) => [
                    e.preventDefault(),
                    setShowSignPopup((prev) => ({ ...prev, signIn: true })),
                  ]}
                >
                  Cloud Compute
                </Link>
                <Link
                  to="#"
                  onClick={(e) => [
                    e.preventDefault(),
                    setShowSignPopup((prev) => ({ ...prev, signIn: true })),
                  ]}
                >
                  Object Storage
                </Link>
              </div>
            </div>
            <div className="mt-4">
              <h5 className="font-[700] mb-3">Contact Us</h5>
              <div className="font-[500] flex gap-2 flex-col text-[#3f405b]">
                <a href="mailto:support@clomd.com">support@clomd.com</a>
              </div>
            </div>
          </div>
        </div>
        <p className="text-[14px] text-center">
          © {new Date().getFullYear()} REECARD LLC. All rights reserved.
        </p>
      </div>
    </div>
  );
};
