import { Footer } from "./Footer/Footer";
import { Header } from "../UI/Main/Header";

export default function Privacy() {
  return (
    <div>
      <Header />
      <div className="mb-[100px] max-md:mb-[50px] mt-[65px] max-md:mt-[40px] max-w-[1440px] mx-auto">
        <div className="flex flex-col w-full h-full max-w-[1440px] px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
          <div className="w-full">
            <h1
              className="text-[38px] mb-[32px] font-[700] max-md:text-[28px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Your Privacy, Our Commitment
            </h1>

            <p className="mb-[30px]">
              At Clomd, we believe your privacy is paramount. As part of{" "}
              <strong>REECARD LLC</strong>, a California-based limited liability
              company, we are dedicated to upholding your privacy and securing
              your data. Our approach is open and transparent, empowering you to
              make informed choices about your online activities.
            </p>

            <div className="mb-3">
              <h4 className="font-[600] text-lg">No Tracking</h4>
              <p>We don't monitor your online behavior.</p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Your Data Stays Yours</h4>
              <p>We never sell your data to anyone.</p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Protected Storage</h4>
              <p>
                Your provided data is stored securely for seamless service
                delivery.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Respecting Your Anonymity</h4>
              <p>We respect the significance of online anonymity.</p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">No User Profiling</h4>
              <p>We don't create profiles to target you with ads.</p>
            </div>

            <p className="mb-[26px] mt-7">
              When you access our services, your device automatically shares
              certain information, like IP address, browser type, and language.
              Additional details, such as screen size and operating system,
              might be shared when requested. However, this information is used
              temporarily to deliver content and ensure security against
              malicious activity. Our apps and website are intended for a
              general audience, and we do not knowingly market to or solicit
              information from children under 13.
            </p>
          </div>

          {/*  */}
          <div className="w-full">
            <h1
              className="text-[38px] mb-[24px] font-[700] max-md:text-[28px] max-md:mb-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              What We Collect
            </h1>

            <div className="mb-3">
              <h4 className="font-[600] text-lg">Account Info</h4>
              <p>
                When you sign up, we collect your email to communicate about
                your account. We won't spam you or sell your data. It might be
                required for some parts of the service.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Payment Details</h4>
              <p>
                For subscription payments, secure processors handle your
                information. Your information is stored according to their
                privacy policies.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Uploaded Files</h4>
              <p>
                Files and any metadata you store with us are stored securely,
                solely for providing our services.
              </p>
            </div>
          </div>

          {/*  */}
          <div className="w-full mt-2">
            <h1
              className="text-[38px] mb-[24px] font-[700] max-md:text-[28px] max-md:mb-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              How We Use Your Data
            </h1>

            <div className="mb-3">
              <h4 className="font-[600] text-lg">Service Delivery</h4>
              <p>Your data supports our file storage and sharing services.</p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Communication</h4>
              <p>
                Your email aids us in account-related communication. We only
                send login links to authorize your account, emails related to
                the Payment Details on your account.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Improvements</h4>
              <p>
                Understanding usage helps us enhance services and resolve
                issues.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Security</h4>
              <p>Your data bolsters platform security and fraud prevention.</p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Authentication</h4>
              <p>
                We employ an authentication cookie stored within your browser,
                cleared upon logout or 7 days.
              </p>
            </div>
          </div>

          {/*  */}
          <div className="w-full mt-2">
            <h1
              className="text-[38px] mb-[24px] font-[700] max-md:text-[28px] max-md:mb-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              When Sharing Becomes Necessary
            </h1>
            <p className="mb-[26px] mt-7">
              While privacy is our priority, instances arise where sharing is
              essential:
            </p>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Trusted Partners</h4>
              <p>
                We collaborate with reliable partners for seamless service
                provision.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Legal Obligations</h4>
              <p>
                If the law demands or our rights need protection, we may
                disclose data.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Business Changes</h4>
              <p>
                During mergers or sales, your data could be part of the
                transition. Your privacy remains a core commitment.
              </p>
            </div>
          </div>

          {/*  */}
          <div className="w-full mt-2">
            <h1
              className="text-[38px] mb-[24px] font-[700] max-md:text-[28px] max-md:mb-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Your Control Matters
            </h1>

            <div className="mb-3">
              <h4 className="font-[600] text-lg">Account Deletion</h4>
              <p>
                You can delete your account at any time, but remember it's
                permanent.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Data Retention</h4>
              <p>
                After deletion, your data is retained for 60 days before
                permanent removal.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Email Choices</h4>
              <p>
                You control the emails you receive by managing account settings.
              </p>
            </div>
          </div>

          <p className="mb-[26px] mt-7">
            We adhere to relevant laws like the General Data Protection
            Regulation (GDPR) and California Consumer Privacy Act (CCPA). All
            our cookies are essential – the only one we have is for
            authentication.
          </p>

          {/*  */}
          <div className="w-full mt-2">
            <h1
              className="text-[38px] mb-[24px] font-[700] max-md:text-[28px] max-md:mb-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Beyond Our Control
            </h1>

            <div className="mb-3">
              <h4 className="font-[600] text-lg">IP Addresses</h4>
              <p>
                Your ISP, hosting provider, and others may see your IP address;
                it's part of internet functioning.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Browser Info</h4>
              <p>
                Your browser may share details with requests. We don't store
                this information.
              </p>
            </div>
            <div className="mb-3">
              <h4 className="font-[600] text-lg">Email Choices</h4>
              <p>
                You control the emails you receive by managing account settings.
              </p>
            </div>
          </div>

          <p className="mb-[26px] mt-7">
            <strong>
              You are not our product, or our user. You're a person with privacy
              that should be respected. We sell cloud storage services.
            </strong>{" "}
            We never make any money from advertising. Our whole collection of
            apps was created to inspire creativity, and make people have a
            secure platform at a fair cost.
          </p>

          <div className="mb-3">
            <h4 className="font-[600] text-lg">Changes and Reach-Out</h4>
            <p>
              This policy may evolve to reflect changes, but we'll keep you
              informed. Questions? Contact us at{" "}
              <a
                href="mailto:support@clomd.com"
                className="text-blue"
                style={{ textDecoration: "underline" }}
              >
                support@clomd.com
              </a>
              . Using our services implies acceptance of our Privacy Policy.
              Your privacy is our foremost concern
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
