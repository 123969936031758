const data = [
  {
    review:
      "It has been a game-changer for our business. It seamlessly integrated into our workflow, providing fast and reliable cloud storage while saving us a significant amount of money.",
    author: "Cloud Architect, Partner",
  },
  {
    review:
      "We not only saved money but also reduced our carbon footprint. It's a win-win!",
    author: "Founder & CTO",
  },
  {
    review:
      "I've recommended it to all my clients. It's not just about cost savings; it's about making a positive impact on the environment without sacrificing performance.",
    author: "IT Consultant, Partner",
  },
];

export const Testimonials = () => {
  return (
    <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px]">
      <h1
        className="text-[48px] max-md:text-[32px] text-center font-[700] mb-[56px] max-md:mb-[32px]"
        style={{ fontFamily: "var(--family-ubuntu)" }}
      >
        Our clients love us
      </h1>
      <div className="flex flex-wrap justify-center gap-x-4 gap-y-8">
        {data?.map((itm, idx) => (
          <div
            className="flex flex-col justify-between items-center max-w-[320px] bg-[#f6f6f6] min-h-full rounded-md p-8"
            key={idx}
          >
            <p className="max-sm:text-sm text-center text-[#3f405b]">
              {itm.review}
            </p>
            <p
              className="text-center text-lg font-[700] mt-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              {itm.author}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
