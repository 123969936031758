import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { rootReducer } from "../../../store";
import { useTheme } from "../../../features/theme/use-theme";

const arrowDown = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6L8 10L12 6"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const User = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [theme] = useTheme();
  const { email } = useSelector((state) => state.verifyUser);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    rootReducer(undefined, "USER_LOGOUT");
    navigate("/");
    window.location.reload();
  };

  const userWrapper = `font-[600] border-[1px] border-solid border-[#EDEDED] py-[10px] px-[20px] rounded-[15px] ${theme === "dark" ? "bg-[#272B30] text-white" : ""}`;

  return (
    <div
      className={userWrapper}
      onClick={() => setIsMenu(!isMenu)}
    >
      <div className="flex items-center cursor-pointer ">
        <span className="mr-4">
          {email.split("@")[0]}
        </span>
        <span>{arrowDown}</span>
      </div>
      {isMenu && (
        <button
          className={`cursor-pointer mt-2 text-[#E52D2D]`}
          onClick={logout}
        >
          Logout
        </button>
      )}
    </div>
  );
};
