import { useMemo } from "react";
import { monthRate, perHour } from "../../utils/constants";

const Quota = ({
  theme,
  onPayment,
  isLoading,
  onCancel,
  data,
  handleChange,
}) => {
  const isDark = useMemo(() => theme === "dark", [theme]);
  return (
    <div>
      <h2
        className={`text-[24px] font-[600] text-center mb-4 ${
          theme === "dark" ? "text-[#92929D]" : ""
        }`}
        style={{ fontFamily: "var(--family-poppins)" }}
      >
        Compute Engine
      </h2>
      <div className="max-w-[650px] mx-auto">
        <h2
          className={`text-[24px] font-[600] mb-2 ${
            theme === "dark" ? "text-[#92929D]" : ""
          }`}
        >
          Enter quota
        </h2>
        <p
          className={`text-sm ${
            isDark ? "text-[#92929D]" : "text-[#1d2433cc]"
          }`}
        >
          Please enter the amount of the quota of servers you want to create.
          You will be added automatically to the waitlist if the server type is
          not available. Servers have expiration dates and terminate on the 1st
          of each month or within 7 days.
        </p>
        <div
          className={`flex flex-col gap-3 w-full mt-4 ${
            isDark ? "text-[#92929D]" : "text-[#1d2433cc]"
          }`}
        >
          <div className="border-[1px] border-solid border-[#e1e6ef] rounded-[8px] p-[11px] cursor-pointer">
            {data?.server?.cpu} vCPUs · {data?.server?.ram} RAM ·{" "}
            {data?.server?.nvme} NVMe · {data?.server?.nic} NIC
          </div>
          <div className="border-[1px] border-solid border-[#e1e6ef] rounded-[8px] p-[11px] cursor-pointer">
            {data?.location?.city} . {data?.location?.country}
          </div>
          <input
            value={data?.serverCount}
            onChange={(e) => handleChange("serverCount", e.target.value)}
            type="number"
            placeholder="Enter the amount of servers which you want to create"
            className="border-[1px] text-sm border-solid border-[#e1e6ef] rounded-[8px] p-[11px]"
          />
          <hr />
          <div className="text-black border-[1px] border-solid border-[#e1e6ef] rounded-[8px] p-[12px] font-[600] cursor-pointer">
            <p className="mb-1">Billing</p>
            <p className="text-sm my-2">
              ${perHour}/hr (${monthRate} a month)
            </p>
            <p className="text-sm">
              ${monthRate * data?.serverCount} per month{" "}
              <span className="font-[400]">expected for</span>{" "}
              {data?.serverCount || 0} servers
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-3 mt-3">
          <button
            className={`font-[600] py-[7px] w-fit rounded-[8px] px-[20px] text-[#141416] bg-white border-[1px] border-solid`}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            disabled={data?.serverCount < 1 || isLoading}
            onClick={onPayment}
            className={`font-[600] py-[7px] w-fit rounded-[8px] px-[20px] text-white ${
              isDark ? "bg-[#818181]" : "bg-[#141416]"
            }`}
          >
            {!isLoading ? "Continue" : "Loading..."}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quota;
