import { Link } from "react-router-dom";
import imageAccent from "../../../assets/images/main/imageAccent.svg";

export const Start = () => {
  return (
    <div className="mb-[100px] max-md:mb-[50px] mt-[85px] max-md:mt-[50px] max-w-[1440px] mx-auto">
      <div className="flex flex-col w-full h-full px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
        <div className="flex justify-between items-center gap-3 w-full text-black">
          <div className="flex flex-col items-start w-full max-w-[579px]">
            <h1
              className="text-[48px] mb-[40px] font-[700] max-md:text-[28px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Cloud with a{" "}
              <span style={{ color: "var(--blue-text)" }}>Mission</span>
            </h1>

            <p className="text-[18px] leading-[25px] mb-[40px]">
              Deploy High Performance and sustainable servers and cloud storage
              with ease and cut your bills by up to 20x compared with
              competitors.
              <div className="mb-4"></div>
              It’s so <strong>sustainable</strong> we have avoided 400K Metric
              Tons of CO2.
            </p>

            <Link
              to="/pricing"
              style={{ background: "var(--blue-text)" }}
              className="px-8 py-[16px] text-white rounded-[15px] font-[500]"
            >
              View Pricing
            </Link>
          </div>
          <div className="flex flex-col items-start w-full max-w-[579px] max-md:hidden">
            <img
              src={imageAccent}
              alt="imageAccent"
              width={579}
              height={423}
              loading="lazy"
              className="w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
