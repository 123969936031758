import share from "../../../assets/images/bucket/menu/share.png";
import getLink from "../../../assets/images/bucket/menu/getLink.png";
import download from "../../../assets/images/bucket/menu/download.png";
import { client } from "../../../API/client";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export const Menu = ({ handleShare, filename, fileID, showMenuHandler }) => {
  const menuRef = useRef(null);
  const { apikey } = useSelector((state) => state.verifyUser);
  const [isCopied, setIsCopied] = useState({
    value: `https://node1.miststorage.com/${filename || fileID}`,
    copied: false,
  });

  const downloadfile = () => {
    client(
      `files/download?${
        filename ? `filename=${filename}` : `fileID=${fileID}`
      }`,
      {
        headers: { "x-api-key": apikey },
      },
    ).then(async (res) => {
      if (res?.downloadURL) {
        toast.promise(downloadFile(res?.downloadURL, filename || fileID), {
          loading: "Downloading...",
          success: <span>Downloaded!</span>,
          error: <span>Something went wrong.</span>,
        });
      }
    });
  };

  const copyToClipboard = async () => {
    setIsCopied((prev) => ({ ...prev, copied: true }));
    toast.success("Link copied");
  };

  const downloadFile = async (url, filename) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to fetch file. Status: ${response.status}`);
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      showMenuHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    setIsCopied({
      value: `https://node1.miststorage.com/${filename || fileID}`,
      copied: false,
    });

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      setIsCopied({
        value: ``,
        copied: false,
      });
    };
  }, []);

  return (
    <div
      className="flex flex-col absolute top-[80px] right-0 z-10 bg-white shadow-[0px_4px_14px_0px_#6C6C6C40] w-full max-w-[192px] rounded-[15px] p-[16px]"
      ref={menuRef}
      onClick={handleClickOutside}
    >
      {/* <ul>
        <li className="flex mb-[10px]">
          <img
            src={share}
            alt="Share"
            className="mr-[8px]"
          ></img>
          <button onClick={handleShare}>Share</button>
        </li>
      </ul> */}
      <ul>
        <CopyToClipboard
          onCopy={copyToClipboard}
          text={isCopied.value}
        >
          <li className="flex mb-[10px]">
            <img
              src={getLink}
              alt="Get Link"
              className="mr-[8px]"
            ></img>
            <button>Get Link</button>
          </li>
        </CopyToClipboard>
      </ul>
      <ul>
        <li className="flex mb-[10px]">
          <img
            src={download}
            alt="Download"
            className="mr-[8px]"
          ></img>
          <button onClick={downloadfile}>Download</button>
        </li>
      </ul>
    </div>
  );
};
