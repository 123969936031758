import { useMemo } from "react";

const ServerType = ({
  theme,
  data,
  handleChange,
  serverTypes,
  onNextStep,
  onCancel,
}) => {
  const isDark = useMemo(() => theme === "dark", [theme]);
  return (
    <div>
      <h2
        className={`text-[24px] font-[600] text-center mb-4 ${
          theme === "dark" ? "text-[#92929D]" : ""
        }`}
        style={{ fontFamily: "var(--family-poppins)" }}
      >
        Compute Engine
      </h2>
      <div className="max-w-[650px] mx-auto">
        <h2
          className={`text-[24px] font-[600] mb-2 ${
            theme === "dark" ? "text-[#92929D]" : ""
          }`}
        >
          Server Type
        </h2>
        <p
          className={`text-sm ${
            isDark ? "text-[#92929D]" : "text-[#1d2433cc]"
          }`}
        >
          All servers include DDOS protection and unlimited bandwidth and at
          least 1 Gbps NIC.
        </p>
        <div
          className={`flex flex-col gap-3 w-full mt-4 ${
            isDark ? "text-[#92929D]" : "text-[#1d2433cc]"
          }`}
        >
          {serverTypes?.map((itm) => (
            <div
              key={itm?._id}
              className={`border-[1px] border-solid ${
                data?.server?._id === itm?._id
                  ? "border-black"
                  : "border-[#e1e6ef]"
              } rounded-[8px] p-[11px] cursor-pointer`}
              onClick={() => handleChange("server", itm)}
            >
              {itm?.cpu} vCPUs · {itm?.ram} RAM · {itm?.nvme} TB NVME ·{" "}
              {itm?.nic} Gbps NIC
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-3 mt-3">
          <button
            className={`font-[600] py-[7px] w-fit rounded-[8px] px-[20px] text-[#141416] bg-white border-[1px] border-solid`}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={`font-[600] py-[7px] w-fit rounded-[8px] px-[20px] text-white ${
              isDark ? "bg-[#818181]" : "bg-[#141416]"
            }`}
            disabled={!data?.server}
            onClick={onNextStep}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServerType;
