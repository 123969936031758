import { BucketHeader } from "../Bucket/BucketHeader/BucketHeader";
import { CreateButton } from "../UI/Bucket/CreateButton";
import { useTheme } from "../../features/theme/use-theme";
import addIcon from "../../assets/images/bucket/icons/cross2.svg";
import ComputingList from "./List";
import { useCallback, useEffect, useState } from "react";
import ServerType from "./ServerType";
import Locations from "./Locations";
import Quota from "./Quota";
import ComputingAPI from "../../API/computing";
import { useSelector } from "react-redux";

const INITIAL_DATA = {
  server: null,
  location: null,
  serverCount: 0,
};

const Computing = () => {
  const [theme] = useTheme();
  const [steps, setSteps] = useState({
    list: true,
    serverType: false,
    location: false,
    quota: false,
    success: false,
    failed: false,
  });

  const { email } = useSelector((state) => state.verifyUser);
  const [instances, setInstances] = useState([]);
  const [locations, setLocations] = useState([]);
  const [serverTypes, setServerTypes] = useState([]);
  const [data, setData] = useState({ ...INITIAL_DATA });
  const [isLoading, setIsLoading] = useState(false);
  const [cardDetail, setCardDetail] = useState(null);

  const handleChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSteps = (step) => {
    let _steps = {};
    Object.keys(steps).forEach((key) => (_steps[key] = false));
    setSteps({ ..._steps, [step]: true });
  };

  const handleSubmit = async () => {
    if (!cardDetail)
      return alert("Please add a payment card to billing section");

    setIsLoading(true);
    try {
      const res = await ComputingAPI.createInstance({
        email,
        servers: data?.serverCount,
        serverId: data?.server?._id,
        locationId: data?.location?._id,
      });

      if (res?.status === 201) {
        handleSteps("list");
        setData({ ...INITIAL_DATA });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getMyInstances = useCallback(
    async (email) => {
      try {
        const res = await ComputingAPI.getMyInstances(email);
        if (res?.status === 200) {
          setInstances(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setInstances],
  );

  const getServerTypes = useCallback(async () => {
    try {
      const res = await ComputingAPI.getServerTypes();
      if (res?.status === 200) {
        setServerTypes(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setServerTypes]);

  const getLocations = useCallback(async () => {
    try {
      const res = await ComputingAPI.getLocations();
      if (res?.status === 200) {
        setLocations(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setLocations]);

  const getCardDetail = async () => {
    const res = await ComputingAPI.getMyCard(email);
    if (res?.status === 200 && res?.data?.data) {
      setCardDetail(res?.data?.data);
    }
  };

  useEffect(() => {
    getMyInstances(email);
    getServerTypes();
    getLocations();
    getCardDetail();
  }, []);

  return (
    <>
      <div
        className={`text-black w-full px-[40px] pb-8 max-md:p-[20px] overflow-y-scroll flex flex-col relative ${
          theme === "dark" ? "bg-[#1B1F27] text-white" : ""
        }`}
      >
        <BucketHeader />
        {steps?.list && (
          <InitialStep
            theme={theme}
            instances={instances}
            onNextStep={() => handleSteps("serverType")}
          />
        )}
        {steps?.serverType && (
          <ServerType
            theme={theme}
            data={data}
            handleChange={handleChange}
            serverTypes={serverTypes}
            onCancel={() => [handleSteps("list"), setData({ ...INITIAL_DATA })]}
            onNextStep={() => handleSteps("location")}
          />
        )}
        {steps?.location && (
          <Locations
            theme={theme}
            data={data}
            handleChange={handleChange}
            locations={locations}
            onCancel={() => [handleSteps("list"), setData({ ...INITIAL_DATA })]}
            onNextStep={() => handleSteps("quota")}
          />
        )}
        {steps?.quota && (
          <Quota
            theme={theme}
            data={data}
            isLoading={isLoading}
            handleChange={handleChange}
            onCancel={() => [
              setIsLoading(false),
              handleSteps("list"),
              setData({ ...INITIAL_DATA }),
            ]}
            onPayment={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

const InitialStep = ({ theme, instances, onNextStep }) => {
  return (
    <>
      <div className="flex w-full flex-wrap items-center justify-between mb-[76px] gap-5 max-md:mb-[40px]">
        <h2
          className={`text-[24px] font-[600] ${
            theme === "dark" ? "text-[#92929D]" : ""
          }`}
        >
          Compute Engine
        </h2>
        <div className="flex items-center gap-2 flex-wrap">
          <CreateButton
            onClick={onNextStep}
            title="Set a quota "
          />
          <button
            className={`flex py-[12px] w-fit rounded-[12px] px-[20px] items-center text-black bg-white border-[1px] border-solid`}
            onClick={onNextStep}
          >
            <img
              width={24}
              height={24}
              src={addIcon}
              alt="addIcon"
            ></img>
            <span className="text-[15px] font-[600] ml-[6px] whitespace-nowrap">
              Create instance
            </span>
          </button>
        </div>
      </div>
      {instances?.length < 1 ? (
        <div className="flex flex-col justify-center items-center">
          <img
            src={require("../../assets/images/bucket/IconNodata.png")}
            className="max-w-[252px] mb-[16px]"
            alt="noDataFound"
          ></img>
          <p className="text-[16px] font-[600] text-center mb-[16px] text-[#1D2433A6]">
            No dedicated servers found. <br /> Set your quota (limits) first.
          </p>
          <CreateButton
            onClick={onNextStep}
            title="Set a quota "
          />
        </div>
      ) : (
        <ComputingList data={instances} />
      )}
    </>
  );
};

export default Computing;
