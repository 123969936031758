import { Start } from "./Start/Start";
import { Services } from "./Services/Services";
import { CountOn } from "./CountOn/CountOn";
import { Footer } from "./Footer/Footer";
import { Testimonials } from "./Testimonials/Testimonials";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setReferral } from "../../features/signInUser-slice";
import { useDispatch } from "react-redux";
import { Header } from "../UI/Main/Header";

export default function Main() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("ref");

  useEffect(() => {
    if (referralCode) {
      dispatch(setReferral(referralCode));
    }
  }, []);

  return (
    <div>
      <Header />
      <Start />
      <CountOn />
      <Services />
      <Testimonials />
      <Footer />
    </div>
  );
};
