const TABLE_HEAD = [
  "Name",
  "IP Address",
  "Region",
  "SSH Login",
  "SSH Password",
  "Size",
  "Expiration Date",
];

const ComputingList = ({ data }) => {
  return (
    <table
      className={`border-collapse border border-slate-400 w-full`}
      style={{ fontFamily: "var(--family-poppins)" }}
    >
      <thead>
        <tr className="bg-[#b9b9b9b0]">
          {TABLE_HEAD?.map((itm, idx) => (
            <th
              key={idx}
              className="p-2 border border-slate-300 text-start text-[13px]"
            >
              {itm}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 &&
          data?.map((itm, idx) => (
            <tr key={itm?._id}>
              <td className="p-3 border border-slate-300 text-sm">
                {itm?.name}
              </td>
              <td className="p-3 border border-slate-300">{itm?.ip}</td>
              <td className="p-3 border border-slate-300">
                {itm?.region?.city}
              </td>
              <td className="p-3 border border-slate-300">{itm?.sshLogin}</td>
              <td className="p-3 border border-slate-300">{itm?.sshPass}</td>
              <td className="p-3 border border-slate-300">
                {itm?.size?.cpu} vCPUs
              </td>
              <td className="p-3 border border-slate-300">
                {new Date(itm?.expiresAt).toLocaleString()}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ComputingList;
