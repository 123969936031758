import { useTheme } from "../../../features/theme/use-theme";

export const CreateButton = ({ onClick, title, isImage }) => {
  const [theme] = useTheme();

  return (
    <button
      className={`flex py-[12px] w-fit rounded-[12px] px-[20px] items-center text-white ${theme === "dark"
        ? "bg-[#818181]"
        : "bg-black"
        }
      `}
      onClick={onClick}
    >
      {!isImage && (
        <img
          src={require("../../../assets/images/bucket/icons/crossImg.png")}
          alt="crossImg"
        ></img>
      )}
      {isImage === "uploadImg" && (
        <img
          src={require("../../../assets/images/bucket/icons/uploadImg.png")}
          alt="uploadImg"
        ></img>
      )}
      {isImage === "categoryImg" && (
        <img
          src={require("../../../assets/images/bucket/icons/categoryImg.png")}
          alt="categotyImg"
        ></img>
      )}
      <span className="text-[15px] font-[600] ml-[6px] whitespace-nowrap">
        {title}
      </span>
    </button>
  );
};
