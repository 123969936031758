import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../../../features/theme/use-theme";

export const NavigateButton = ({
  title,
  img,
  fontSize,
  fontFamily,
  onClick,
  to,
}) => {
  const [theme] = useTheme();
  const location = useLocation();

  const ordinaryLink = `flex ${fontSize} ${fontFamily} font-[500] w-full max-w-[202px]
  rounded-[10px] mb-[12px] pl-[16px] py-[12px] pr-[0px] duration-300 pb-[15px] ${
    theme === "dark"
      ? "hover:bg-[#818181] text-[#d3d3d3]"
      : "hover:bg-[#141416]"
  } hover:text-white `;
  const activeLink =
    theme === "dark" ? ` bg-[#818181] text-white` : ` bg-[#141416] text-white`;

  const setActive = (to) => {
    if (location.pathname === to) {
      return ordinaryLink + activeLink;
    }
    return ordinaryLink;
  };

  const isActive = (to) => {
    if (location.pathname === to) {
      return "_active";
    }
  };

  return (
    <Link
      className={setActive(to)}
      onClick={onClick}
      to={to}
    >
      <div
        className={`w-[20px] h-[20px] link-icon ${
          theme === "dark" ? "_dark" : "_light"
        } ${isActive(to)}`}
      >
        {img}
      </div>
      <span className="ml-[10px]">{title}</span>
    </Link>
  );
};
