import { Link } from "react-router-dom";
import { Header } from "../UI/Main/Header";
import { Footer } from "./Footer/Footer";
import objectIcon from "../../assets/images/main/object.svg";
import trashIcon from "../../assets/images/main/trash.svg";
import diskIcon from "../../assets/images/main/disk.svg";

const data = [
  {
    icon: objectIcon,
    title: "50,000 Homes",
    desc: "Average single centers power consumption per year",
  },
  {
    icon: trashIcon,
    title: "2 Million Tons",
    desc: "Data centers e-waste (4% of all humanity’s waste) per year",
  },
  {
    icon: diskIcon,
    title: "8 Exabytes",
    desc: "Unused data storage across 12M+ computers and devices",
  },
];

const data2 = [
  {
    title: "Datacenters require a lot of cooling!",
    desc: "In most data centers today, cooling accounts for greater than 40 percent of electricity usage. North America, most data centers draw power from “dirty” electricity grids, especially in Virginia’s “data center alley,” the site of 70 percent of the world’s internet traffic in 2019.",
  },
  {
    title: "Hyperscale Datacenters",
    desc: "While some of the most advanced, “hyperscale” data centers, like those maintained by Google, Facebook, and Amazon, have pledged to transition their sites to carbon-neutral via carbon offsetting and investment in renewable energy infrastructures like wind and solar, many of the smaller-scale data centers that I observed lack the resources and capital to pursue similar sustainability initiatives.",
  },
  {
    title: "Cloud now has a greater footprint than the airline industry",
    desc: "According to a Lawrence Berkeley National Laboratory report, if the entire Cloud shifted to hyperscale facilities, energy usage might drop as much as 25 percent. Without any regulatory body or agency to incentive or enforce such a shift in our infrastructural configuration.",
  },
  {
    title: "Large companies use carbon offsets that are often a ripoff",
    desc: "Carbon offsets are credits for renewable energy that are meant to compensate for emissions made elsewhere. Large cloud computing companies use carbon offsets when they don’t often work (4% of offsets actually remove CO2).",
  },
  {
    title: "Humans don’t actually utilize their full computing space",
    desc: "When purchasing a new computer, the disposal of old working computers contributes to the growing issue of e-waste. E-waste, which includes discarded, recycled, or refurbished electrical and electronic products, accounts for an estimated 70% of our overall toxic waste. Unfortunately, only about 12.5% of e-waste is recycled, while a staggering 85% ends up in landfills and incinerators.",
  },
  {
    title: "Data center utilization rates can be as low as 15 percent or less",
    desc: "Despite the low utilization rates of around 10-15%, data centers consume a significant amount of energy, with estimates ranging from 196 to 400 terawatt-hours (TWh) in 2020, equivalent to 1% to 2% of the world's annual data center energy consumption.",
  },
];

export default function Sustainability() {
  return (
    <div>
      <Header />

      <div className="mb-[100px] max-md:mb-[50px] mt-[85px] max-md:mt-[50px] max-w-[1440px] mx-auto">
        <div className="flex flex-col w-full h-full max-w-[1440px] px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
          <div className="flex items-center flex-col gap-y-4 w-full text-center">
            <h1
              className="text-[42px] mb-[32px] font-[700] max-md:text-[28px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              Cloud with Momentum
            </h1>

            <p className="text-[18px] max-sm:text-base max-w-[575px] mb-[30px]">
              Most cloud providers aren’t sustainable. They continue to build
              new “sustainable” datacenters but they really aren’t sustainable.
            </p>

            <Link
              to="#"
              style={{ background: "var(--blue-text)" }}
              className="px-8 py-[16px] text-white rounded-[15px] font-[500]"
            >
              Read more
            </Link>
          </div>
        </div>
      </div>

      <div
        className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px]"
        style={{ background: "var(--blue-text)" }}
      >
        <h1
          className="text-[42px] font-[700] max-md:text-[28px] max-sm:text-[18px] text-white text-center"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Distributed Computing - E-Waste = Sustainable Cloud
        </h1>
      </div>

      <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px] bg-[#fbfbfb]">
        <h1
          className="text-[48px] max-md:text-[28px] text-center font-[700] mb-[60px] max-md:mb-[32px]"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Legacy Cloud + Neglect of Resources
        </h1>
        <div className="flex flex-wrap justify-center gap-x-12 gap-y-8">
          {data?.map((itm, idx) => (
            <div
              className="flex flex-col items-center max-w-[330px] max-xl:max-w-[270px] max-lg:max-w-[230px]"
              key={idx}
            >
              <div className="w-[80px] h-[80px] bg-[#f3f3f3] rounded-lg grid place-items-center">
                <img
                  src={itm.icon}
                  width={60}
                  height={60}
                  alt="Cloud Computing"
                />
              </div>
              <h5
                className="text-xl max-md:text-lg font-[700] my-4"
                style={{ fontFamily: "var(--family-ubuntu)" }}
              >
                {itm.title}
              </h5>
              <p className="max-sm:text-sm text-center text-[#3f405b]">
                {itm.desc}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px] max-w-[1440px] mx-auto">
        <h1
          className="text-[48px] text-center mb-[32px] font-[700] max-md:text-[28px] max-md:mb-[20px]"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Didn’t think that was enough?
        </h1>

        <p className="text-[18px] max-sm:text-base max-w-[575px] text-center mb-[30px] mx-auto">
          Here’s more about how the current cloud industry operates
        </p>

        <div className="flex gap-4 justify-center flex-wrap">
          {data2.map((itm, idx) => (
            <div
              key={idx}
              className="border-[1px] border-solid p-4 border-[#c9c9c9] rounded-md min-h-full w-[500px] max-w-[500px] max-xl:w-[450px] max-xl:max-w-[450px] max-md:w-full max-md:max-w-full"
            >
              <p className="font-[600] text-sm">{itm.title}</p>
              <p className="font-[600] pl-2 mt-2">{itm.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}
