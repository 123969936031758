export const useCacheUpload = () => {
  const uploadFile = async (url, file) => {
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: file,
      });

      if (!response.ok) throw new Error("Failed to fetch");
      const data = await response;
      return data;
    } catch (error) {
      console.error("Error during file upload:", error.message);
      throw error;
    }
  };

  return [uploadFile];
};
