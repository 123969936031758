import { createContext, useContext, useRef, useState } from "react";

const ModalContext = createContext();

export const useModalContext = () => {
  return useContext(ModalContext);
};

export const ModalContextProvider = ({ children }) => {
  const refSignUp = useRef(null);
  const refSignIn = useRef(null);
  const refVerifySignUp = useRef(null);

  const [showSignPopup, setShowSignPopup] = useState({
    signIn: false,
    signUp: false,
  });
  const [showVerifySignPopup, setShowVerifySignPopup] = useState(false);

  const changeVisibilitySign = (value, type) => {
    if (type === "signIn") {
      setShowSignPopup((prev) => ({ ...prev, signIn: value }));
    }
    if (type === "signUp") {
      setShowSignPopup((prev) => ({ ...prev, signUp: value }));
    }
  };

  return (
    <ModalContext.Provider
      value={{
        refSignUp,
        refSignIn,
        refVerifySignUp,
        showVerifySignPopup,
        setShowVerifySignPopup,
        changeVisibilitySign,
        setShowSignPopup,
        showSignPopup,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
