export const client = async (endPoint, { body, ...customConfig }) => {
  const API = `https://api.clomd.com/${endPoint}`;

  const headers = {
    "Content-Type": "application/json;charset=utf-8",
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(API, config);

    if (!response.ok) {
      if (response?.status == 401) {
        localStorage.removeItem("persist:root");
        window.location.href="/";
      }
      throw new Error("failed to fetch");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    return console.log(error.message);
  }
};
