import { Link } from "react-router-dom";
import { Header } from "../UI/Main/Header";
import { Footer } from "./Footer/Footer";

const data = [
  {
    title: "Step 1: Ethical Device Contribution",
    desc: "Individuals and businesses contribute their idle computing resources (CPU, GPU, and storage) by installing our lightweight software on their devices."
  },
  {
    title: "Step 2: Task Allocation and Load Balancing",
    desc: "Our AI algorithms dynamically allocate computing tasks based on the available resources of each device. Tasks are distributed efficiently to ensure optimal performance and resource utilization across the network."
  },
  {
    title: "Step 3: Replication",
    desc: "Frequently accessed data is duplicated across multiple active devices, ensuring redundancy. In the event of a device going offline or being powered down, data is replicated on other active machines, preventing data loss. We also use unused secure environments on data centers to handle this allocation. "
  },
  {
    title: "Step 4: Security Measures and Encryption",
    desc: "All data transmitted and stored on the cloud is encrypted using robust end-to-end encryption protocols. Devices communicate securely within the network, preventing unauthorized access or data breaches. With help from secured under utilized data centers we make sure that your tasks are encrypted to the network. "
  },
  {
    title: "Step 5: Dynamic Scaling",
    desc: "Data generated by the distributed computing network is securely stored in the cloud. Cloud storage and additional compute power allows for dynamic scaling, accommodating the growing volume of data generated by an expanding network."
  },
  {
    title: "Step 6: Compliance and Audits",
    desc: "Our partners and both Tier 3/4 data centers have ISO, GDPR, SOC and additional compliance built in, they continuously handle audits and the security of the cloud."
  },
]

export default function HowWorks() {
  return (
    <div>
      <Header />

      <div className="mb-[100px] max-md:mb-[50px] mt-[85px] max-md:mt-[50px] max-w-[1440px] mx-auto">
        <div className="flex flex-col w-full h-full max-w-[1440px] px-[85px] items-center max-md:px-[50px] max-sm:px-[20px]">
          <div className="flex items-center flex-col gap-y-4 w-full text-center">
            <h1
              className="text-[42px] mb-[32px] font-[700] max-md:text-[32px] max-md:mb-[20px]"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              How Our Distributed Computing Network Works
            </h1>

            <p className="text-[18px] max-sm:text-base max-w-[575px] mb-[30px]">
              We designed the network to be smart, secure, dynamic, and work
              with your use case and business. It can handle anything cloud.
            </p>

            <Link
              to="#"
              style={{ background: "var(--blue-text)" }}
              className="px-8 py-[16px] text-white rounded-[15px] font-[500]"
            >
              Read more
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full p-[85px] pt-0 max-md:p-[50px] max-md:pt-0 max-sm:p-[26px] max-sm:pt-0 max-w-[1440px] mx-auto">
        <div className="flex gap-4 justify-center flex-wrap">
          {data.map((itm, idx) => (
            <div
              key={idx}
              className="border-[1px] border-solid p-4 border-[#c9c9c9] rounded-md min-h-full w-[500px] max-w-[500px] max-xl:w-[450px] max-xl:max-w-[450px] max-md:w-full max-md:max-w-full"
            >
              <p className="font-[600] text-sm">{itm.title}</p>
              <p className="font-[600] pl-2 mt-2">{itm.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};
