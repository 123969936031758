import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ClomdLogo from "../../../assets/images/main/clomdLogo.svg";
import burger from "../../../assets/images/main/hamburger.svg";
import { ReactComponent as Close } from "../../../assets/images/main/close.svg";
import { SignPopup } from "../../UI/Popups/SignPopup";
import { VerifyPopup } from "../../UI/Popups/VerifyPopup";
import { handleClickOutside } from "../../../customHooks/handleClickOutside";
import { useModalContext } from "../../../context/modalContext";

const linksWrapperPhone = "fixed top-0 left-0";

const linksWrapperPhoneVisible =
  "w-full h-full overflow-hidden flex flex-col items-center justify-center fixed top-0 left-0 backdrop-blur-[6px] z-10 text-black";

const LINKS = [
  { title: "Pricing", to: "/pricing" },
  { title: "Uptime", to: "/uptime" },
  { title: "How it works", to: "/how-it-works" },
  { title: "Sustainability", to: "/sustainability" },
];

export const Header = () => {
  const location = useLocation();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const {
    refSignUp,
    refSignIn,
    refVerifySignUp,
    showVerifySignPopup,
    setShowVerifySignPopup,
    setShowSignPopup,
    showSignPopup,
  } = useModalContext();

  useEffect(() => {
    setIsShowMenu(false);
  }, [location.pathname]);

  return (
    <>
      <div className="w-full px-[1rem] py-3 flex justify-between items-center text-black">
        <Link to="/">
          <img
            className="max-md:w-[120px] max-md:h-[52px]"
            src={ClomdLogo}
            alt="Clomd Logo"
            loading="lazy"
            width={150}
            height={65}
          />
        </Link>

        <div className="max-md:hidden flex gap-8 max-lg:gap-6 items-center font-[500] text-base max-lg:text-sm">
          {LINKS?.map((itm, idx) => (
            <Link
              key={idx}
              to={itm.to}
            >
              {itm.title}
            </Link>
          ))}
        </div>

        <span
          className="hidden max-md:grid place-items-center"
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          <img
            alt="Clomd"
            width={22}
            height={17}
            src={burger}
          ></img>
        </span>

        <div className="flex max-md:hidden items-center gap-3">
          <button
            className="text-base py-[11px] px-6"
            onClick={() =>
              setShowSignPopup((prev) => ({ ...prev, signUp: true }))
            }
          >
            Signup
          </button>
          <button
            className="bg-black text-white text-base py-[11px] px-6 rounded-[10px]"
            onClick={() =>
              setShowSignPopup((prev) => ({ ...prev, signIn: true }))
            }
          >
            Login
          </button>
        </div>
      </div>

      {isShowMenu && (
        <div
          className={isShowMenu ? linksWrapperPhoneVisible : linksWrapperPhone}
        >
          <div className="shadow-lg shadow-blue-500/50 h-fit flex flex-col bg-white w-1/2 justify-between items-center p-[20px] relative">
            <div className="flex gap-4 items-center flex-col font-[500] text-base">
              {LINKS?.map((itm, idx) => (
                <Link
                  key={idx}
                  to={itm.to}
                >
                  {itm.title}
                </Link>
              ))}
            </div>

            <button
              className="text-base py-[8px] px-5 rounded-[10px] border-solid mt-4"
              onClick={() => {
                setShowSignPopup((prev) => ({ ...prev, signUp: true }));
                setIsShowMenu(false);
              }}
            >
              Signup
            </button>
            <button
              className="bg-black text-white text-base py-[9px] px-7 rounded-[10px] mt-4"
              onClick={() => {
                setShowSignPopup((prev) => ({ ...prev, signIn: true }));
                setIsShowMenu(false);
              }}
            >
              Login
            </button>

            <button
              onClick={() => setIsShowMenu(false)}
              className="w-[30px] absolute right-3 top-3"
            >
              <Close />
            </button>
          </div>
        </div>
      )}

      {showSignPopup.signUp === true && (
        <SignPopup
          type={"signUp"}
          refSignUp={refSignUp}
          setShowSignPopup={setShowSignPopup}
          setShowVerifySignPopup={setShowVerifySignPopup}
          clickOutside={(e) =>
            handleClickOutside(
              e,
              refSignUp,
              setShowSignPopup,
              "signUp",
              showSignPopup,
            )
          }
        />
      )}
      {showSignPopup.signIn === true && (
        <SignPopup
          type={"signIn"}
          setShowVerifySignPopup={setShowVerifySignPopup}
          setShowSignPopup={setShowSignPopup}
          refSignIn={refSignIn}
          clickOutside={(e) =>
            handleClickOutside(
              e,
              refSignIn,
              setShowSignPopup,
              "signIn",
              showSignPopup,
            )
          }
          cancelClick={() =>
            setShowSignPopup({ ...showSignPopup, signIn: false })
          }
        />
      )}
      {showVerifySignPopup === true && (
        <VerifyPopup
          refVerifySignUp={refVerifySignUp}
          clickOutside={(e) =>
            handleClickOutside(e, refVerifySignUp, setShowVerifySignPopup)
          }
          closeClick={() =>
            setShowVerifySignPopup({
              ...showVerifySignPopup,
              signInPopup: false,
            })
          }
        />
      )}
    </>
  );
};
