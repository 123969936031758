import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_COMPUTING_API_URL;

export default class ComputingAPI {
  //get locations
  static getLocations = async () => {
    try {
      const res = await axios.get(`api/v1/computing/location`);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //get server types
  static getServerTypes = async () => {
    try {
      const res = await axios.get(`api/v1/computing/server`);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //get my instances
  static getMyInstances = async (email) => {
    try {
      const res = await axios.get(
        `api/v1/computing/instance/my?email=${email}`,
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //create instance
  static createInstance = async (data) => {
    try {
      const res = await axios.post(`api/v1/computing/instance`, data);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //get my card
  static getMyCard = async (email) => {
    try {
      const res = await axios.get(`api/v1/card?email=${email}`);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //save my card
  static saveMyCard = async (data) => {
    try {
      const res = await axios.post(`api/v1/card`, data);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //createCharges
  static createCharges = async (data) => {
    try {
      const res = await axios.post(`api/v1/card/charges`, data);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };
}
