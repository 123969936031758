import { Header } from "../UI/Main/Header";
import { Footer } from "./Footer/Footer";
import computeIcon from "../../assets/images/bucket/Compute.svg";
import objectIcon from "../../assets/images/bucket/Buckets.svg";
import arrowIcon from "../../assets/images/main/arrowRight.svg";
import tickIcon from "../../assets/images/main/tick.svg";
import { useModalContext } from "../../context/modalContext";

const data = [
  {
    title: "Prevent outrageous charges",
    desc: "On demand pricing without any minimums, stop paying for reserved instances or forgetting about terminating instances. We protect you from forgetting to terminate your instances. ",
  },
  {
    title: "No Egress, transfer or API call fees",
    desc: "Stop paying for internet fees, egrees fees. It’s not just us, our clients also hate them too! “For our company we store a lot of files but need to fetch them. Clomd and the team helped us decrease our egress fees by $25M.",
  },
  {
    title: "Dormant Resources into Opportunities for Cost Savings",
    desc: "Clomd is a network made to utilize unused compute resources on datacenters and our distributed computing network built for your wallet. Stop spending so much on cloud. There’s Clomd for that.",
  },
  {
    title: "Up to 20x lower pricing then the giants",
    desc: "Stop paying ridiculous prices for cloud services. Clomd offers unbeatable value with transparent pricing, no hidden fees, and cost-efficient solutions tailored to your needs.",
  },
];

const cloudCompute = [
  "SSH and TCP/UDP Ports",
  "DDOS Protection",
  "Unlimited Bandwidth",
  "NO DATA TRANSFER FEES",
  "NO HIDDEN FEES",
];

const objectStorage = [
  "Developer API Access",
  "5 GB Max File Size",
  "Unlimited Storage",
  "NO DATA TRANSFER FEES",
  "NO HIDDEN FEES",
];

export default function Sustainability() {
  const { setShowSignPopup } = useModalContext();
  return (
    <div>
      <Header />

      <div className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px] max-w-[1440px] mx-auto">
        <h1
          className="text-[48px] text-center mb-[32px] font-[700] max-md:text-[30px] max-md:mb-[20px]"
          style={{ fontFamily: "var(--family-ubuntu)" }}
        >
          Pricing
        </h1>

        <p className="text-[18px] max-sm:text-base max-w-[575px] text-center mb-[30px] mx-auto">
          Full transparency and up to 20x lower TCO with our flexible pricing
          plans
        </p>

        <div className="flex gap-4 justify-center flex-wrap">
          {data.map((itm, idx) => (
            <div
              key={idx}
              className="border-[1px] border-solid p-4 border-[#c9c9c9] rounded-md min-h-full w-[500px] max-w-[500px] max-xl:w-[450px] max-xl:max-w-[450px] max-md:w-full max-md:max-w-full"
            >
              <p className="font-[600] text-sm">{itm.title}</p>
              <p className="font-[600] pl-2 mt-2">{itm.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <div
        className="w-full p-[85px] pt-0 max-md:p-[50px] max-md:pt-0 max-sm:p-[26px] max-sm:pt-0 max-w-[1440px] mx-auto"
        style={{ fontFamily: "var(--family-ubuntu)" }}
      >
        <h6
          className="text-[12px] w-fit mx-auto px-3 py-1 font-[600] mb-14 border-[1px] border-solid border-[#d8d8d8] rounded-[48px] bg-[#fbfbfb]"
          style={{ letterSpacing: "0.8px" }}
        >
          PAY AS YOU GO
        </h6>
        <div className="flex gap-y-8 gap-x-16 justify-center flex-wrap">
          <div>
            <p className="text-xl max-sm:text-lg font-[700] mb-6 flex items-center gap-2">
              <img
                src={computeIcon}
                alt="Cloud Compute"
                width={20}
                height={20}
              />
              <span>Cloud Compute</span>
            </p>
            <p>
              <span className="text-5xl font-[700]">$0.01</span>{" "}
              <span className="text-[#787878]">/hr</span>
            </p>
            <p className="text-[#787878] mt-4 mb-5">per vCPU billed weekly</p>
            <button
              className="bg-black text-white font-[700] w-[300px] h-[48px] flex justify-between items-center rounded-[17px]"
              style={{ padding: "6px 6px 6px 20px" }}
              onClick={() =>
                setShowSignPopup((prev) => ({ ...prev, signIn: true }))
              }
            >
              <span>Get Started</span>
              <span className="w-[34px] h-[38px] grid place-items-center bg-white rounded-[12px]">
                <img
                  src={arrowIcon}
                  alt="Get Started"
                  width={7}
                  height={12}
                />
              </span>
            </button>
            <p className="text-sm my-4">Features Included:</p>
            {cloudCompute.map((itm, idx) => (
              <div
                key={idx}
                className="flex items-center gap-4 mb-4 last:mb-0"
              >
                <p className="grid place-items-center rounded-full w-6 h-6 bg-[#e1eef3]">
                  <img
                    src={tickIcon}
                    alt="Tick Icon"
                    width={12}
                    height={9}
                  />
                </p>
                <span className="text-[#585858]">{itm}</span>
              </div>
            ))}
          </div>
          <div>
            <p className="text-xl max-sm:text-lg font-[700] mb-6 flex items-center gap-2">
              <img
                src={objectIcon}
                alt="Object Storage"
                width={24}
                height={24}
              />
              <span>Object Storage</span>
            </p>
            <p>
              <span className="text-5xl font-[700]">$2.5</span>{" "}
              <span className="text-[#787878]">/per TB</span>
            </p>
            <p className="text-[#787878] mt-4 mb-5">billed monthly</p>
            <button
              className="bg-black text-white font-[700] w-[300px] h-[48px] flex justify-between items-center rounded-[17px]"
              style={{ padding: "6px 6px 6px 20px" }}
              onClick={() =>
                setShowSignPopup((prev) => ({ ...prev, signIn: true }))
              }
            >
              <span>Get Started</span>
              <span className="w-[34px] h-[38px] grid place-items-center bg-white rounded-[12px]">
                <img
                  src={arrowIcon}
                  alt="Get Started"
                  width={7}
                  height={12}
                />
              </span>
            </button>
            <p className="text-sm my-4">Features Included:</p>
            {objectStorage.map((itm, idx) => (
              <div
                key={idx}
                className="flex items-center gap-4 mb-4 last:mb-0"
              >
                <p className="grid place-items-center rounded-full w-6 h-6 bg-[#e1eef3]">
                  <img
                    src={tickIcon}
                    alt="Tick Icon"
                    width={12}
                    height={9}
                  />
                </p>
                <span className="text-[#585858]">{itm}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
