import cloudIcon from "../../../assets/images/main/cloud.svg";
import objectIcon from "../../../assets/images/main/object.svg";

const data = [
  {
    icon: cloudIcon,
    title: "Cloud Compute",
    desc: "Spin up NVME virtual machines in 40 locations. DDOS protection included.",
  },
  {
    icon: objectIcon,
    title: "Object Storage",
    desc: "Object storage with no egress fees and at 1/20 the the price of AWS.",
  },
];

export const Services = () => {
  return (
    <div
      id="services"
      className="w-full p-[85px] max-md:p-[50px] max-sm:p-[26px] bg-[#fbfbfb]"
    >
      <h1
        className="text-[48px] max-md:text-[32px] text-center font-[700] mb-[56px] max-md:mb-[32px]"
        style={{ fontFamily: "var(--family-ubuntu)" }}
      >
        Our Services
      </h1>
      <div className="flex flex-wrap justify-center gap-x-12 gap-y-8">
        {data?.map((itm, idx) => (
          <div
            className="flex flex-col items-center max-w-[320px]"
            key={idx}
          >
            <div className="w-[80px] h-[80px] bg-[#f3f3f3] rounded-lg grid place-items-center">
              <img
                src={itm.icon}
                width={60}
                height={60}
                alt="Cloud Computing"
              />
            </div>
            <h5
              className="text-xl max-md:text-lg font-[700] my-4"
              style={{ fontFamily: "var(--family-ubuntu)" }}
            >
              {itm.title}
            </h5>
            <p className="max-sm:text-sm text-center text-[#3f405b]">
              {itm.desc}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
